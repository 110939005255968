import { ActivityType } from "../actvities/activity.types";
import { SubscriptionPlan, UsageLimitRule } from "./subscription.types";

export const ACTIVITY_DESCRIPTIONS: Record<ActivityType, string> = {
  LOGIN: "User logged in",
  PURCHASE_SUBSCRIPTION: "User purchased a subscription",
  CANCEL_SUBSCRIPTION: "User cancelled a subscription",
  START_SESSION:
    "Over 10 years of transcripts from any publicly traded company.",
  SUMMARIZE_DEFAULT:
    "Summarize a conference call using an Industry Expert prompt.",
  // SUMMARIZE_CUSTOM: "Summarize a conference call using your own custom prompt.",
  // ANSWER_QUESTION: "Each question from an answered questionnaire.",
  CHAT_MESSAGE: "Each question or message you send to AI about a company.",
};

export const findUsageLimit = (
  { plan, action }: { plan: string; action: ActivityType },
  limits: UsageLimitRule[]
) => {
  let usageLimit = usageLimitRules.find(
    (rule) => rule.plan === plan && rule.action === action
  );
  return {
    ...usageLimit,
    description: ACTIVITY_DESCRIPTIONS[action],
  };
};

export const getUsageLimitRules = (filter?: {
  plan?: SubscriptionPlan;
  action?: ActivityType;
}) => {
  let rules = usageLimitRules
    .filter((r) => (filter?.plan ? r.plan === filter?.plan : true))
    .filter((r) => (filter?.action ? r.action === filter?.action : true))
    .map((r) => ({
      ...r,
      description: ACTIVITY_DESCRIPTIONS[r.action],
    }));
  return rules;
};

export const usageLimitRules: UsageLimitRule[] = [
  /**
   * FREE PLAN
   */
  {
    title: "Transcripts Accessed",
    action: "START_SESSION",
    limit: 15,
    period: "month",
    plan: "free",
  },
  {
    title: "AI Messages Sent",
    action: "CHAT_MESSAGE",
    limit: 12,
    period: "month",
    plan: "free",
    custom: {
      maxMessageSize: 1000,
    },
  },
  /**
   * BASIC PLAN
   */
  {
    title: "Transcripts Accessed",
    action: "START_SESSION",
    limit: 120,
    period: "month",
    plan: "basic",
  },
  // {
  //   title: "Industry Expert Summaries",
  //   action: "SUMMARIZE_DEFAULT",
  //   limit: 500,
  //   period: "month",
  //   plan: "basic",
  // },
  {
    title: "AI Messages Sent",
    action: "CHAT_MESSAGE",
    limit: 200,
    period: "month",
    plan: "basic",
    custom: {
      maxSize: 2000,
    },
  },
  /**
   * The Axe PLAN
   */
  {
    title: "Transcripts Accessed",
    action: "START_SESSION",
    limit: 500,
    period: "month",
    plan: "pro",
  },
  // {
  //   title: "Industry Expert Summaries",
  //   action: "SUMMARIZE_DEFAULT",
  //   limit: 1000,
  //   period: "month",
  //   plan: "pro",
  // },
  {
    title: "AI Messages Sent",
    action: "CHAT_MESSAGE",
    limit: 500,
    period: "month",
    plan: "pro",
    custom: {
      maxSize: 8000,
      maxInputTokens: 32_000,
    },
  },
];
